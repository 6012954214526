import "./Search.css";
import React, { useEffect, useState } from "react";
import {
  Container,
  Dropdown,
  DropdownButton,
  FormControl,
  InputGroup,
  Stack,
} from "react-bootstrap";
import logo from "../dodosaga.png";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { debounce } from "lodash";
import ReactPaginate from "react-paginate";

const groups = {
  areuniana: "A Reuniana",
  chandon: "Chandon",
  diarioreuniao: "Diário Reunião",
  ecie: "Egrégio",
  nchandon: "Novo Chandon",
  vreinomauricio: "Mauritius",
};

const SAGA_URL = "https://search.saga.re/";

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const removeEmpty = (obj) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, v]) => v != null && v !== "")
  );
};

const getNewParams = (obj) =>
  "/?" + new URLSearchParams(removeEmpty(obj)).toString();

const getSearch = async (params, cb) => {
  const result = await axios.get(
    SAGA_URL + "?" + new URLSearchParams(removeEmpty(params)).toString()
  );
  cb(result);
};

const debouncedSearch = debounce((params, cb) => getSearch(params, cb), 300);

const Search = () => {
  const query = useQuery();
  const navigate = useNavigate();

  const SearchResult = ({
    url,
    subject,
    date,
    author,
    group,
    msgnum,
    snippet,
  }) => (
    <div className="saga-search-result">
      <span style={{ opacity: 0.7 }}>{url}</span>
      <h1>
        <a href={url} dangerouslySetInnerHTML={{ __html: subject }}></a>
      </h1>
      <span style={{ opacity: 0.7 }}>
        {date} - {author} - {group} #{msgnum}
      </span>
      <br />
      <span dangerouslySetInnerHTML={{ __html: snippet }}></span>
    </div>
  );

  const [curParams, setCurParams] = useState({
    g: query.get("g"),
    q: query.get("q"),
    p: query.get("p", 1),
  });

  const [result, setResult] = useState({ results: [] });

  useEffect(() => {
    if (curParams.q) {
      debouncedSearch(curParams, (r) => {
        setResult(r.data);
        document.title = "SAGA - " + curParams.q;
      });
    } else {
      setResult({ results: [] });
      document.title = "SAGA";
    }
  }, [curParams]);

  const termChange = (e) => {
    const newParams = { ...curParams, q: e.target.value, p: 1 };
    setCurParams(newParams);
    navigate(getNewParams(newParams));
  };

  const groupChange = (e) => {
    const newParams = { ...curParams, g: e };
    setCurParams(newParams);
    navigate(getNewParams(newParams));
  };

  const pageChange = (e) => {
    const newParams = { ...curParams, p: e.selected + 1 };
    setCurParams(newParams);
    window.scrollTo(0, 0);
    navigate(getNewParams(newParams));
  };

  let TopResults = () => "";
  let Pages = () => "";

  if (result.results.length > 0) {
    TopResults = () => (
      <small>
        Página {curParams.p} de {Math.ceil(result.total / 10.0)}. Cerca de{" "}
        {result.total} resultados ({result.took} ms).
      </small>
    );

    Pages = () => (
      <div className="saga-pagination">
        <div style={{ display: "inline-block" }}>
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            pageCount={Math.ceil(result.total / 10.0)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={pageChange}
            breakClassName={"break-me"}
            containerClassName={"pagination"}
            subContainerClassName={"pagination-list"}
            activeLinkClassName={"pagination-active"}
            previousLinkClassName={"pagination-link"}
            pageLinkClassName={"pagination-link"}
            nextLinkClassName={"pagination-link"}
            forcePage={curParams.p - 1}
          />
        </div>
      </div>
    );
  }

  return (
    <Container fluid className="saga-container">
      <Stack gap={0}>
        <div className="saga-top">
          <span>
            <a href="https://www.reuniao.org/">Sacro Império de Reunião</a> |{" "}
            <a href="http://msgs.saga.re/">Índice de Mensagens</a> |{" "}
            <a href="https://archivo.reuniao.re">Archivo Imperial</a>
          </span>
        </div>
        <div className="saga-header">
          <a href="/">
            <div style={{ textAlign: "center" }}>
              <img src={logo} className="saga-search-logo" alt="logo" />
              <span className="saga-title">SAGA</span>
            </div>
          </a>
        </div>
        <div className="saga-search">
          <Container>
            <InputGroup>
              <FormControl
                aria-label="Busca"
                placeholder="Busca"
                onChange={termChange}
                value={curParams.q || ""}
              />

              <DropdownButton
                variant="dark"
                title={curParams.g === null ? "Tudo" : groups[curParams.g]}
                id="input-group-dropdown-2"
                onSelect={groupChange}
              >
                <Dropdown.Item key={"tudo"} eventKey={null}>
                  Tudo
                </Dropdown.Item>
                {Object.keys(groups).map((name) => (
                  <Dropdown.Item key={name} eventKey={name}>
                    {groups[name]}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </InputGroup>
          </Container>
        </div>
        <Container className="saga-search-results">
          <TopResults />
          {result.results.map((r) => SearchResult(r))}
          <Pages />
        </Container>
      </Stack>
    </Container>
  );
};

export default Search;
